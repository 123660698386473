var render = function render(){var _vm=this,_c=_vm._self._c;return _c('tr',[_c('td',[_c('strong',[_vm._v(_vm._s(_vm.inspection.id))])]),_c('td',{staticClass:"nowrap-column"},[_vm._v(" Компьютерная диагностика: "),(_vm.inspection.computer_diagnostic_count)?_c('strong',{staticClass:"font-medium"},[_vm._v("есть ошибки")]):_c('strong',{staticClass:"font-medium"},[_vm._v("нет ошибок")])]),_c('td',{staticClass:"nowrap-column"},[_vm._v(" "+_vm._s(_vm.inspection.creator.name)+" (ID "+_vm._s(_vm.inspection.creator.id)+") ")]),_c('td',{staticClass:"nowrap-column"},[_vm._v(" "+_vm._s(_vm.inspection.created_date)+" ")]),_c('td',{staticClass:"nowrap-column"},[_c('a',{staticClass:"btn btn-success",staticStyle:{"margin":"3px 2px 2px 3px"},attrs:{"href":_vm.reportSourcePath + _vm.inspection.car_id + _vm.inspection.car_id + '_' + _vm.inspection.id + '_report.jpg',"target":"_blank","title":"Открыть","type":"button"}},[_c('i',{staticClass:"fas fa-file"})])]),_c('td',{staticClass:"nowrap-column"},[(([1, 7, 8, 9].includes(_vm.$store.getters['auth/authUser'].role.id) ||
      _vm.inspection.creator.id === _vm.$store.getters['auth/authUser'].id ||
      _vm.inspection.car.creator.id === _vm.$store.getters['auth/authUser'].id))?_c('router-link',{staticClass:"btn btn-primary",staticStyle:{"margin":"3px 2px 2px 3px"},attrs:{"to":{ name: 'car.inspection.edit', params: {
                   carInspectionId: _vm.inspection.id,
                   carId: _vm.inspection.car_id,
                   inspection: _vm.inspection,
                 }},"title":"Редактировать","type":"button"}},[_c('i',{staticClass:"fa fa-edit"})]):_vm._e()],1),_c('td',{staticClass:"nowrap-column"},[_c('button',{staticClass:"btn btn-danger",staticStyle:{"margin":"3px 2px 2px 3px"},attrs:{"title":"Удалить","type":"button"},on:{"click":_vm.removeInspection}},[_c('i',{staticClass:"fa fa-trash"})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }